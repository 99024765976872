//Packages ---->

import CryptoJS from 'crypto-js';

export const encryptObject = (encryptValue, SecretKey) => {
    try {
        let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(encryptValue), SecretKey).toString();
        return ciphertext
    }
    catch (err) {
        console.log("encryptObject_err", err)
        return ''
    }
}


export const decryptObject = (decryptValue, SecretKey) => {
    try {
        let bytes = CryptoJS.AES.decrypt(decryptValue, SecretKey);
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData
    }
    catch (err) {
        console.log(err, 'decryptObject__err')
        return ''
    }
}

