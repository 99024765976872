// import package
import React, { useContext, useEffect, useRef, useState } from "react";

// import component
import GridItem from "components/Grid/GridItem.js";
import defaultProfileLight from "../../assets/ryfin/images/defaultProfileLight.png";
import defaultProfileDark from "../../assets/ryfin/images/defualtProfileDark.png";

import { GlobalContext } from "../../context/Context";
import { updateuserprofilepichooks } from "../../actions/P2PorderAction";
import { Getsingleuserhook } from "actions/P2PorderAction";
import config from "../../config/index";
import Images from "../../Images";
import { useSelector } from "react-redux";
import { toastAlert } from "../../lib/toastAlert";

const ProfileChange = () => {
  const userdata = useSelector((state) => state);

  const [image, setImage] = useState("");
  const [imageblob, setImageblob] = useState("");
  const [userdatas, setUserdata] = useState({});

  const [imgerror, setImgerror] = useState("");

  const imageRef = useRef(null);

  const { currentTheme } = useContext(GlobalContext);

  const [profileImage, setProfileImage] = useState(
    currentTheme == "light" ? defaultProfileLight : defaultProfileDark
  );

  const handleChooseProfile = () => {
    imageRef.current.click();
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    var payload = { userid: userdata?.account?.userId };
    var result = await Getsingleuserhook(payload);
    // setReview(result?.data?.data?.reviews);
    if (result?.data?.type == "success") {
      setUserdata(result?.data?.data);
      console.log(userdatas, "userdatas");
      if (result?.data?.data?.profileImage) {
        setImageblob(config.API_URL + result?.data?.data?.profileImage);
      }
    }
  };

  const handleupdate = async () => {
    if (image) {
      const formdata = new FormData();
      formdata.append("userid", userdata?.account?.userId);
      formdata.append("image", image);
      var payload = {
        userid: userdata?.account?.userId,
        image: image,
      };
      var result = await updateuserprofilepichooks(formdata);
      console.log("result in updarte profilepic", result);
      if (result?.data?.data?.profileImage) {
        toastAlert("success", "Profileimage updated successfully!");
        result.data.data.profileImage =
          config.API_URL + result?.data?.data?.profileImage;
        // updateAcctData(result?.data?.data)
        window.location.reload();
      }
      if (result?.status == "failed") {
        toastAlert("error", "Invalid image");
      }
    }
    // else if(result?.data?.data?.profileImage !== ""){
    //   toastAlert("success" , "Profileimage updated successfully!");
    // }
    else {
      toastAlert("error", "Profileimage is required!");
    }
  };
  const handleFile = async (e) => {
    console.log("HANDLE FILE");
    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/;

    const { name, files } = e.target;
    let fileSize = files[0].size;
    let filesize = Math.round(fileSize / 1024);
    const maxsize = 9 * 1024;
    if (filesize > maxsize) {
      setImgerror("image size is large");
      return false;
    } else if (filesize > 10000000) {
      // 10 MB
      setImgerror("TOO_LARGE");
      return false;
    } else if (!imageFormat.test(files[0].name)) {
      setImgerror("INVALID_IMAGE");
      return false;
    } else {
      setImgerror("");
    }
    setImage(files[0]);
    setImageblob(URL.createObjectURL(files[0]));
    // let formData = { ...formValue, ...{ [name]: files[0] } }
    // setFormValue(formData)
    // if (!isEmpty(validateError)) {
    //   setImgerror("")
    // }
  };

  return (
    <GridItem xs={12} sm={12} md={4} lg={4}>
      <div className="">
        <label>
          Profile Image<span class="textRed">*</span>
        </label>
        <div className="re-profile__image-wrap">
          <img src={imageblob ? imageblob : Images.user} alt="profile" />
        </div>
        <input
          ref={imageRef}
          type="file"
          name="profile"
          className="d-none"
          onChange={(e) => {
            handleFile(e);
          }}
        />
        <p className="error-message mt-3">{imgerror}</p>
      </div>
      <div className="re-profile__image-button--actions">
        <button
          type="button"
          className="btn btn-primary text-uppercase py-2 my-0"
          onClick={handleChooseProfile}
        >
          Choose
        </button>
        <button
          type="button"
          className="btn btn-primary text-uppercase py-2 my-0 ml-3"
          onClick={() => handleupdate()}
        >
          {/* <i class="fas fa-spinner fa-spin"></i> */}
          Update
        </button>
      </div>
    </GridItem>
  );
};
export default ProfileChange;
