import React from "react";
import ReactDOM from "react-dom";
import './index.css';

import App from './App';
import { Provider } from "react-redux";
import store from './store';
import GlobalState from "context/Context";

ReactDOM.render(
    <Provider store={store}>
        <GlobalState>
            <App />
        </GlobalState>
    </Provider>,
    document.getElementById("root"));


// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//     <GlobalState>
//         <App />
//     </GlobalState>
// );