// import package
import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { useSelector } from 'react-redux'

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import config from "../../config/index";
// import local 

import announce1 from '../../assets/ryfin/images/announce1.png'
import announce2 from '../../assets/ryfin/images/announce2.png'
import announce3 from '../../assets/ryfin/images/announce3.png'
import announce4 from '../../assets/ryfin/images/announce4.png'
import announce5 from '../../assets/ryfin/images/announce5.png'
import announcejson from '../../assets/ryfin/anim/announcement.json'
import { momentFormat } from 'lib/dateTimeHelper';




const announementData = [
    {
        id: 1,
        image: announce1,
        date: "Wed Sep 18,2024",
        title: 'Announcement 1',
        desc: "LoremLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        id: 2,
        image: announce2,
        date: "Wed Sep 18,2024",
        title: 'Announcement 2',
        desc: "LoremLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        id: 3,
        image: announce3,
        date: "Wed Sep 18,2024",
        title: 'Announcement 3',
        desc: "LoremLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        id: 4,
        image: announce4,
        date: "Wed Sep 18,2024",
        title: 'Announcement 4',
        desc: "LoremLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        id: 5,
        image: announce5,
        date: "Wed Sep 18,2024",
        title: 'Announcement 5',
        desc: "LoremLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },

]

const SiteAnnouncement = () => {
    const anncData = useSelector(state => state.announcement);
    console.log("🚀 ~ SiteAnnouncement ~ anncData:", anncData)

    const [currentAnnouncement, setCurrentAnnouncement] = useState(1)

    const handleViewAnnouncement = (id) => {

    }


    return (
        <div className="p2p_card min-h-auto">
            <h5 className="login_title_8 new_dashboard">Announcement</h5>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6} className="p-0 mt-2">
                    <div className='re-dash__announce-list'>
                        {anncData && anncData.map((news, i) => (
                            <div className='re-dash__announce-container' key={i}>
                                <div className='re-dash__announce-image'>
                                    <img src={config.API_URL + "/images/anouncement/" + news.image} alt='announce' />
                                </div>
                                <div className='re-dash__announce-content'>
                                    <p className='re-dash-announce-content--date'>End Date :{momentFormat(news.endDateTime, 'DD-MM-YYYY HH:mm')}</p>
                                    <p className='re-dash-announce-content--title'>{news.content}</p>
                                    {/* <p className='re-dash-announce-content--desc'>
                                        {news.desc.slice(0, 180)} ...
                                    </p> */}
                                    {/* <div className='re-dash-announce-content--buttons'>
                                        <button className='readmore'>Read More</button>
                                    </div> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} className="p-0  re-dash__announce-anim--grid">
                    <div className='re-dash__announce-anim d-flex justify-content-center align-items-center h-100'>
                        <Lottie
                            animationData={announcejson}
                            className=""
                            loop={true} />
                    </div>
                </GridItem>

            </GridContainer>
        </div >
    )
}

export default SiteAnnouncement;