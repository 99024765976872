// import axios, { handleResp } from "../config/axios";
import axios, { handleResp } from "../../config/axios";




export const Createp2porderhooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/create-p2p-orders`,
            'method': 'post',
            'data': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const Getcoinlisthooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/get-coinlist`,
            'method': 'get',
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}


export const Filterp2porderhooks = async (datas, dispatch) => {
    console.log('filterp2porder_dats', datas)
    try {
        let respData = await axios({
            'url': `p2papi/filter-p2p-orders`,
            'method': 'post',
            'data': datas
        });
        console.log('filterp2porder_res', respData)
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const Getalloffertaghook = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/get-offettag`,
            'method': 'get',
            'data': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const Getpreferedcurrency = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/get-preferedcurrency`,
            'method': 'get',
            'data': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const Getsingleuserhook = async (datas, dispatch) => {
    try {
        console.log("wallet payload", datas);
        let respData = await axios({
            'url': `p2papi/getsingleuser`,
            'method': 'get',
            'params': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const Getorderchathook = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/getsingletradechat`,
            'method': 'get',
            'params': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const canceltradehook = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/cancel-trade`,
            'method': 'post',
            'data': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const createroom = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/createroom`,
            'method': 'post',
            'data': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const getuserofferhooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/user-offer`,
            'method': 'get',
            'params': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const getsingletradehooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/get-singlesaledetail`,
            'method': 'get',
            'params': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const Adduserreviewhooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/adduser-review`,
            'method': 'post',
            'data': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const updateorderstatushooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/update-order-status`,
            'method': 'post',
            'data': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const updateAssethooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/assetupdate`,
            'method': 'post',
            'data': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const getcurrencydatahooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/get-currency-data`,
            'method': 'get',
            'params': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}



export const updateuserstatushooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/update-user-status`,
            'method': 'post',
            'data': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}


export const Getcmshooks = async (datas, dispatch) => {
    try {
        console.log("wallet payload", datas);
        let respData = await axios({
            'url': `p2papi/get-cms`,
            'method': 'get',
            'params': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const Getfaqhooks = async (datas, dispatch) => {
    try {
        console.log("wallet payload", datas);
        let respData = await axios({
            'url': `p2papi/get-faq`,
            'method': 'get',
            // 'params' : datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const getsitesettingshook = async (datas, dispatch) => {
    try {
        console.log("wallet payload", datas);
        let respData = await axios({
            'url': `p2papi/get-sitesettings`,
            'method': 'get',
            // 'params' : datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const gettradehistoryhook = async (datas, dispatch) => {
    try {
        console.log("wallet payload", datas);
        let respData = await axios({
            'url': `p2papi/get-tradehistory`,
            'method': 'get',
            'params': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const getyourrequesthook = async (datas, dispatch) => {
    try {
        console.log("wallet payload", datas);
        let respData = await axios({
            'url': `p2papi/get-your-request`,
            'method': 'get',
            'params': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const getuserbalancehook = async (datas, dispatch) => {
    try {
        console.log("wallet payload", datas);
        let respData = await axios({
            'url': `p2papi/get-user-balance`,
            'method': 'get',
            'params': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const gettradespeedhook = async (datas, dispatch) => {
    try {
        console.log("wallet payload", datas);
        let respData = await axios({
            'url': `p2papi/get-trade-speed`,
            'method': 'get',
            'params': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}


export const updateuserprofilepichooks = async (datas, dispatch) => {
    try {
        console.log(datas, "axios data");
        let respData = await axios({
            'url': `p2papi/update-profile-pic`,
            'method': 'post',
            'data': datas,
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const cancelofferhooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/cancel-offer`,
            'method': 'post',
            'data': datas,
            'headers': {
                'Content-Type': 'application/json',
            }
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const getpaymenttypeshook = async (datas, dispatch) => {
    try {
        console.log("wallet payload", datas);
        let respData = await axios({
            'url': `p2papi/get-paymenttypes`,
            'method': 'get',
            'params': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const createcryptoaddressshook = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/create-addresss`,
            'method': 'post',
            'data': datas,
            'headers': {
                'Content-Type': 'application/json',
            }
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const Getmessagenotificationhooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/get-message-notification`,
            'method': 'get',
            'params': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const markasreadonehooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/markus_readone`,
            'method': 'post',
            'data': datas,
            'headers': {
                'Content-Type': 'application/json',
            }
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const markasreadallhooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/markas-readall`,
            'method': 'post',
            'data': datas,
            'headers': {
                'Content-Type': 'application/json',
            }
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const Getunreadmessagenotificationhooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/getunreadmessagenotification`,
            'method': 'get',
            'params': datas
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}

export const Checkdeposithooks = async (datas, dispatch) => {
    try {
        let respData = await axios({
            'url': `p2papi/check-deposit`,
            'method': 'post',
            'data': datas,
            'headers': {
                'Content-Type': 'application/json',
            }
        });
        return respData;
    }
    catch (err) {
        console.log("error", err);
        handleResp(err, 'error')
        return {
            status: "failed",
        }
    }
}