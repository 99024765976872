// import packages
import axios from 'axios';

// import lib
import config from './index';
import { getAuthToken, removeAuthToken } from '../lib/localStorage'
import isLogin from '../lib/isLogin';
import { encryptObject, decryptObject } from '../hooks/cryptoJs'
import isEmpty from 'lib/isEmpty';

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common['Authorization'] = getAuthToken();

export const setAuthorization = (token) => {
    axios.defaults.headers.common['Authorization'] = token;
}

export const removeAuthorization = () => {
    delete axios.defaults.headers.common["Authorization"];
}


export const handleResp = (respData, type = 'success') => {
    try {
        if (isLogin() && type == 'error' && respData && respData.response && respData.response.status == 401) {
            removeAuthToken()
            removeAuthorization()
            window.location.href = '/login'
            return true
        }
    } catch (err) {
        return false
    }
}


export const Axios = async (reqData) => {
    try {
        if (!isEmpty(reqData.data)) {
            let token = encryptObject(reqData.data, !isEmpty(reqData.secretKey) ? reqData.secretKey : config.CRYPTO_SECRET_KEY)
            delete reqData.data
            reqData['data'] = { token: token }
        }
        console.log("AxiosAxios", reqData)
        const response = await axios(reqData)
        if (!isEmpty(response)) {
            let respData = decryptObject(response.data, !isEmpty(reqData.secretKey) ? reqData.secretKey : config.CRYPTO_SECRET_KEY)
            return { data: respData }
        }
    } catch (err) {
        if (err?.response?.data?.statusCode == 401) {
            // userLogout()
        } if (err?.response?.status == 429) {
            return { data: err?.response?.data }
        }
        let respData = decryptObject(err.response.data, !isEmpty(reqData.secretKey) ? reqData.secretKey : config.CRYPTO_SECRET_KEY)
        return { data: respData }
    }
}


export default axios;