// import package
import React, { useContext, useEffect, useInsertionEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Button, MenuItem } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux'
import Ticker from 'react-ticker'
import Lottie from 'lottie-react';
import Marquee from "react-fast-marquee";



// import components
import HeaderLinks from "components/Header/HeaderLinks.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Home from '../components/Home';
import P2pTrading from '../components/Home/P2pTrading';
import FaqTrend from '../components/Home/FaqTrend';
import { NavLink } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa6";

import landingjson from '../assets/ryfin/anim/Landing.json'
import choosejson from '../assets/ryfin/anim/hover.json'

import circlePlayJson from '../assets/ryfin/anim/circleplay.json'
import circlePlayLightJson from '../assets/ryfin/anim/circleplaylight.json'
import top10Json from '../assets/ryfin/anim/logo_dark.json'
import top10JsonLight from '../assets/ryfin/anim/logobg.json'


import tradeChooseBg from '../assets/ryfin/images/trade-choose-bg.png'
import tradeAnywhere from '../assets/ryfin/images/trade-anywhere.png'
import webSupport from '../assets/ryfin/images/webSupport.svg'
import support from '../assets/ryfin/images/support.svg'
import youtube from '../assets/ryfin/images/youtube.svg'
import facebook from '../assets/ryfin/images/facebook.svg'
import linkedin from '../assets/ryfin/images/linkedin.svg'
import discord from '../assets/ryfin/images/discord.svg'
import youtubeLight from '../assets/ryfin/images/youtubeLight.svg'
import facebookLight from '../assets/ryfin/images/facebookLight.svg'
import linkedinLight from '../assets/ryfin/images/linkedinLight.svg'
import discordLight from '../assets/ryfin/images/discordLight.svg'
import youtubeHover from '../assets/ryfin/images/youtubeHover.svg'
import facebookHover from '../assets/ryfin/images/facebookHover.svg'
import linkedinHover from '../assets/ryfin/images/linkedinHover.svg'
import discordHover from '../assets/ryfin/images/discordHover.svg'
import youtubeLightHover from '../assets/ryfin/images/youtubeLightHover.svg'
import facebookLightHover from '../assets/ryfin/images/facebookLightHover.svg'
import linkedinLightHover from '../assets/ryfin/images/linkedinLightHover.svg'
import discordLightHover from '../assets/ryfin/images/discordLightHover.svg'
import spoticon from '../assets/ryfin/images/spoticon.svg'
import buycryptoicon from '../assets/ryfin/images/buycryptoicon.svg'
import earnicon from '../assets/ryfin/images/earnicon.svg'
import marquee1 from '../assets/ryfin/images/marquee1.png'
import marquee2 from '../assets/ryfin/images/marquee2.png'
import marquee3 from '../assets/ryfin/images/marquee3.png'
import marquee4 from '../assets/ryfin/images/marquee4.png'
import marquee5 from '../assets/ryfin/images/marquee5.png'
import marquee6 from '../assets/ryfin/images/marquee6.png'
import blurOne from '../assets/ryfin/images/blurone.png'
import blurTwo from '../assets/ryfin/images/blurtwo.png'




// import action
import { getLanguage, getAllCMSPage } from '../actions/commonAction';
import { getCmsData } from '../actions/homeAction';
import { getAncontent } from '../actions/commonAction';

// import lib
import isEmpty from "../lib/isEmpty";
import { useTranslation } from 'react-i18next';
import { setAccountData } from "actions/users";
import HeaderNew from "components/Header/HeaderNew";
import { GlobalContext } from "context/Context";






const dashboardRoutes = [];


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "RYFIN"
  }, []);
  return null;
}

const HomePage = () => {


  const { currentTheme } = useContext(GlobalContext);

  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [spot, setSpotTrade] = useState('')
  const [derivative, setDerivative] = useState('')
  const [p2p, setP2p] = useState('')
  const [encrypt, setencrypt] = useState('')
  const [wallet, setWallet] = useState('')
  const [p2pTrade, setP2pTrade] = useState('')
  const [coin, setSetCoin] = useState('')
  const [cmsData, setCmsData] = useState([])
  // redux
  const { isAuth } = useSelector(state => state.auth)
  const language = useSelector(state => state.language)
  const socialMedia = useSelector(state => state.socialMedia);
  console.log("social", socialMedia)
  const [tickerclose, setTickerclose] = useState(false);
  const [anncData, setAnncData] = useState([])

  const [chooseLottieLoop, setChooseLottieLoop] = useState(true)





  const handleMouseHover = () => {
    setTimeout(setChooseLottieLoop(false), 2000)
  }

  const handleMouseRemove = () => {
    setChooseLottieLoop(true)
  }

  // const closeTicker = () =>
  // {

  // }

  const fetchCmsPage = async () => {
    try {
      const findLang = localStorage.getItem('lang')
      let data = {
        lang: findLang
      }
      const { status, loading, result } = await getAllCMSPage(data);
      if (status == 'success') {
        let spotTrade = result.find(item => item.identifier == 'home_spot')
        setSpotTrade(spotTrade)
        let derivativeTraded = result.find(item => item.identifier == 'home_derivative')
        setDerivative(derivativeTraded)
        let p2pContent = result.find(item => item.identifier == '2fa_protected')
        setP2p(p2pContent)
        let encryption = result.find(item => item.identifier == 'home_encryption')
        setencrypt(encryption)
        let wallet = result.find(item => item.identifier == 'home_wallet')
        setWallet(wallet)
        let p2pTrading = result.find(item => item.identifier == 'home_p2p')
        setP2pTrade(p2pTrading)
        let coinContent = result.find(item => item.identifier == 'home_coin')
        setSetCoin(coinContent)
      }
    } catch (err) { }
  }

  // identifier



  const fetchAnnouncemet = async () => {
    const { status, loading, result } = await getAncontent()
    console.log("getANNC", result)
    if (status === "success") {
      setAnncData(result)
    }
  }
  anncData && console.log("anncData", anncData)
  const fetchCmsData = async () => {
    try {
      let reqData = {

      }
      const { status, loading, result } = await getCmsData();
      if (status == 'success') {
        setCmsData(result)
      }
    } catch (err) { }
  }
  const createMarkup = (a) => {

    return { __html: a };
  }
  // function
  useEffect(() => {
    if (isEmpty(language)) {
      getLanguage(dispatch)
    }
  }, [])
  useEffect(() => {
    fetchCmsPage()
    fetchCmsData()
    fetchAnnouncemet()
  }, [])




  return (

    // <div className={tickerclose ? "page_wrap home_page_header_banner without_ticker_banner" : "page_wrap home_page_header_banner"}>
    <div >

      <ScrollToTopOnMount />
      {/* <div className={tickerclose ? "upper_slider d-none" : "upper_slider"}>
        <div className="banner_running_ticker">
          <i className="fas fa-bullhorn"></i>
          {anncData && anncData.length > 0 &&

            <Ticker>
              {({ index }) => (
                <>
                  {anncData && anncData.length > 0 ? <p>{anncData && anncData.length > 0 && anncData[anncData.length - 1].content}
                  </p> : <p></p>}

                </>)}
            </Ticker>
          }
          <span className="close_icon_ticker" onClick={() => setTickerclose(true)}><i className="fas fa-times"></i></span>

        </div>
      </div> */}
      <HeaderNew />
      {/* <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
      /> */}




      {/* <Home /> */}


      <main className="re-main">

        <section className="re-home__banner">
          <div className="re-container ">
            <div className="re-home__banner--row row mx-auto align-items-center pl-xl-5 pe-xl-4">
              <div className="col-12 col-xl-6">

                <div className="re-home__banner-left">
                  <p className="title">
                    <span>Secure Investing
                    </span> for <br /> Every Traders</p>
                  <p className="description mt-3">
                    Lorem ipsum dolor sit amet consectetur. Hendrerit praesent at urna lorem morbi sit vehicula.
                  </p>

                  <div className="re-home__banner-buttons d-flex justify-content-center justify-content-xl-start">
                    <Link className="text-decoration-none" 
                    // to='/spot/ETH_USDT'
                    >
                      <button className="re-theme__button--primary" >Learnmore</button>
                    </Link>
                    <Link className="text-decoration-none" 
                    // to='/spot/ETH_USDT'
                    >                      <button className="re-theme__button--secondary">Get Started</button>
                    </Link>
                  </div>

                </div>
              </div>
              <div className="col-12 col-xl-6 pr-0 d-flex justify-content-center justify-content-xl-end">
                <div className="re-banner__right--anim">
                  <div className="re-home__banner-right d-flex justify-content-xl-end">
                    {/* <img src={bannerAnimation} className="img-fluid" /> */}
                    <Lottie
                      animationData={landingjson}
                      className="re-home__banner--lottie"
                      loop={true} />
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="re-home__banner--runner d-flex align-items-center">
            <Marquee className="re-home__banner--marquee">
              <div className="d-flex align-items-center marque_text_wrap">
                <img src={marquee1} alt="marquee" />
                <img src={marquee2} alt="marquee" />
                <img src={marquee3} alt="marquee" />
                <img src={marquee4} alt="marquee" />
                <img src={marquee5} alt="marquee" />
                <img src={marquee6} alt="marquee" />
              </div>
            </Marquee>
          </div>

        </section>

        <section className="re-section__animation position-relative">
          {/* <img src={blurOne} className="re-section__animation-elipse--one position-absolute" alt="elipse" /> */}
          <img src={blurTwo} className="re-section__animation-elipse--two position-absolute d-none d-xl-block" alt="elipse" />

          <div className="re-container">
            <div className="re-section__animation-styles">
              <p className="re-home__section--title-vh">
                Why Choose Ryfin Exchange
              </p>
              <div className="re-home__section--descWrap-vh mx-auto">
                <p className="re-home__section--desc-vh  mt-3">
                  We are an leading digital currency exchange platform with a secure and peace of mind features for everyone who wish to invest and trade digital currency.
                </p>
              </div>

              {/* start of mobile */}

              <div className="d-md-none d-flex flex-column align-items-center re-section__animation-row--mob">

                <div className="re-section__animation-row--leftBg">
                  <div className="re-section__animation-row--left d-flex align-items-end">
                    <div className="re-section__animation-row--leftContent">
                      <p className="re-section__animation--cardTitle mb-0">
                        Secure Storage
                      </p>
                      <p className="re-section__animation--cardDesc mb-0">We support the crypto assets we are handling. We provide 24/7 real-time security monitoring for enhanced security.</p>
                    </div>
                  </div>
                </div>
                <div className="re-section__animation-row--rightBg mt-4">
                  <div className="re-section__animation-row--right d-flex align-items-end">
                    <div className="re-section__animation-row--leftContent">
                      <p className="re-section__animation--cardTitle mb-0">
                        Optimized User Experience
                      </p>
                      <p className="re-section__animation--cardDesc mb-0">We support the crypto assets we are handling. We provide 24/7 real-time security monitoring for enhanced security.</p>
                    </div>
                  </div>
                </div>
                <div className="re-section__animation-row--leftBg mt-4">
                  <div className="re-section__animation-row--left d-flex align-items-end">
                    <div className="re-section__animation-row--leftContent">
                      <p className="re-section__animation--cardTitle mb-0">
                        Technical Stability
                      </p>
                      <p className="re-section__animation--cardDesc mb-0">Industry-leading technology which is upgraded frequently ensures a stable trading environment.</p>
                    </div>
                  </div>
                </div>
                <div className="re-section__animation-row--rightBg mt-4">
                  <div className="re-section__animation-row--right d-flex align-items-end">
                    <div className="re-section__animation-row--leftContent">
                      <p className="re-section__animation--cardTitle mb-0">
                        Rewarding
                      </p>
                      <p className="re-section__animation--cardDesc mb-0">We support the crypto assets we are handling.</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of mobile */}



              {/* start of web */}

              <div className="d-none d-md-block">
                <div className="re-section__animation-row">
                  <div className="re-section__animation-row--leftBg">
                    <div className="re-section__animation-row--left d-flex align-items-end">
                      <div className="re-section__animation-row--leftContent">
                        <p className="re-section__animation--cardTitle mb-0">
                          Secure Storage
                        </p>
                        <p className="re-section__animation--cardDesc mb-0">We support the crypto assets we are handling. We provide 24/7 real-time security monitoring for enhanced security.</p>
                      </div>
                    </div>
                  </div>
                  <div className="re-section__animation-row--centerBg">
                    <div className="re-section__animation-row--center">
                    </div>
                    <div className="re-section__animation-center--json">
                      <Lottie
                        animationData={currentTheme == 'light' ? circlePlayLightJson : circlePlayJson}
                        className="re-section__animation-center--lottie"
                        loop={true} />
                    </div>
                  </div>
                  <div className="re-section__animation-row--rightBg">
                    <div className="re-section__animation-row--right  d-flex align-items-end">
                      <div className="re-section__animation-row--cardContent">
                        <p className="re-section__animation--cardTitle mb-0">
                          Optimized User Experience
                        </p>
                        <p className="re-section__animation--cardDesc mb-0">We support the crypto assets we are handling. We provide 24/7 real-time security monitoring for enhanced security.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="re-section__animation-row--bottom">
                  <div className="re-section__animation-row--leftBg">
                  </div>
                  <div className="re-section__animation-row--bottomCenter ">
                    <div className="re-section__bottomCenter--left d-flex align-items-end">
                      <div className="re-section__animation-row--cardContent">
                        <p className="re-section__animation--cardTitle-sm mb-0">
                          Technical Stability
                        </p>
                        <p className="re-section__animation--cardDesc-sm mb-0">Industry-leading technology which is upgraded frequently ensures a stable trading environment.</p>
                      </div>
                    </div>
                    <div className="re-section__bottomCenter--right d-flex align-items-end">
                      <div className="re-section__animation-row--cardContent">
                        <p className="re-section__animation--cardTitle-sm mb-0">
                          Rewarding
                        </p>
                        <p className="re-section__animation--cardDesc-sm mb-0">Industry-leading technology which is upgraded frequently ensures a stable trading environment.</p>
                      </div>
                    </div>
                  </div>

                  <div className="re-section__animation-row--rightBg">
                  </div>
                </div>
              </div>

              {/* end of web */}

            </div>
          </div>

        </section>
        <section className="re-section__top10">
          <div className="re-container">
            <p className="re-home__section--title-vh">
              Top 10 cryptos will be integrated in the platform.
            </p>
            <div className="re-home__section--descWrap-vh mx-auto">
              <p className="re-home__section--desc-vh mt-3">
                Lorem ipsum dolor sit amet consectetur. Hendrerit praesent at urna lorem morbi sit vehicula. At quis ut tortor pellentesque proin sapien urna ultricies malesuada.
              </p>
            </div>
            <div className="re-home__banner-buttons d-flex justify-content-center">
            <Link className="text-decoration-none" 
                    // to='/spot/ETH_USDT'
                    >                <button className="re-theme__button--primary">Learnmore</button>
              </Link>
              <Link className="text-decoration-none" 
                    // to='/spot/ETH_USDT'
                    >                <button className="re-theme__button--secondary">Get Started</button>
              </Link>

            </div>

            <div className="re-section__top10-json d-flex justify-content-center">
              <Lottie
                animationData={currentTheme == 'light' ? top10JsonLight : top10Json}
                className="re-section__top10-lottie"
                loop={true} />
            </div>
          </div>
        </section>

        <section className="re-section__discover position-relative">
          <img src={blurOne} className="re-section__discover-elipse--one position-absolute d-none d-xl-block" alt="elipse" />
          <img src={blurTwo} className="re-section__discover-elipse--two position-absolute d-none d-xl-block" alt="elipse" />
          <div className="re-container">
            <p className="re-home__section--title-vh">
              Discover Our Products
            </p>
            <div className="re-home__section--descWrap-vh mx-auto">
              <p className="re-home__section--desc-vh mb-0">
                Trade a variety of products with Ryfin Exchange based on your preferences.
              </p>
            </div>

            <div className="re-discover__cards ">
              <div className="re-discover__card d-flex flex-column justify-content-between">
                <div className="d-flex align-items-start justify-content-between re-discover__card-header">
                  <div>
                    <p className="re-discover__card--title mb-0">
                      Spot
                    </p>
                    <p className="re-discover__card--desc mb-0">
                      The Ryfin exchange is fast, safe and secure, allowing you to conduct transactions of digital currencies at any time with peace of mind.
                    </p>
                  </div>
                  <img src={spoticon} className="re-discover__card--img" />
                </div>
                <div>
                <Link className="text-decoration-none" 
                    // to='/spot/ETH_USDT'
                    >                    <button className="re-discover__card--button">
                      Learnmore <FaAngleRight />
                    </button>
                  </Link>
                </div>
              </div>
              <div className="re-discover__card d-flex flex-column justify-content-between">
                <div className="d-flex align-items-start justify-content-between re-discover__card-header">
                  <div>
                    <p className="re-discover__card--title mb-0">
                      Buy Crypto
                    </p>
                    <p className="re-discover__card--desc mb-0">
                      Buy and sell a wide range of cryptocurrencies quickly & easily.
                    </p>
                  </div>
                  <img src={buycryptoicon} className="re-discover__card--img" />
                </div>
                <div>
                <Link className="text-decoration-none" 
                    // to='/spot/ETH_USDT'
                    >                    <button className="re-discover__card--button">
                      Learnmore <FaAngleRight />
                    </button>
                  </Link>
                </div>
              </div>
              <div className="re-discover__card d-flex flex-column justify-content-between">
                <div className="d-flex align-items-start justify-content-between re-discover__card-header">
                  <div>
                    <p className="re-discover__card--title mb-0">
                      Earn
                    </p>
                    <p className="re-discover__card--desc mb-0">
                      Invest and get some of the best rates in the industry - BTC, ETH, USDT and more. Stay turned for updates as we prepare new issues from here.
                    </p>
                  </div>
                  <img src={spoticon} className="re-discover__card--img" />
                </div>
                <div>
                <Link className="text-decoration-none" 
                    // to='/spot/ETH_USDT'
                    >                    <button className="re-discover__card--button">
                      Learnmore <FaAngleRight />
                    </button>
                  </Link>
                </div>
              </div>
              <div className="re-discover__card d-flex flex-column justify-content-between">
                <div className="d-flex align-items-start justify-content-between re-discover__card-header">
                  <div>
                    <p className="re-discover__card--title mb-0">
                      Earn
                    </p>
                    <p className="re-discover__card--desc mb-0">
                      Invest and get some of the best rates in the industry - BTC, ETH, USDT and more. Stay turned for updates as we prepare new issues from here.
                    </p>
                  </div>
                  <img src={spoticon} className="re-discover__card--img" />
                </div>
                <div>
                <Link className="text-decoration-none" 
                    // to='/spot/ETH_USDT'
                    >                    <button className="re-discover__card--button">
                      Learnmore <FaAngleRight />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="re-combine__trade-choose">
          <section className="re-tradeAny pt-5 pt-md-0">
            <div className="re-container">
              <div className="row">
                <div className="col-md-6 d-flex align-items-center justify-content-center re-tradeany__col--left">
                  <div className="re-tradeAny__left d-flex justify-content-center">
                    <img src={tradeAnywhere} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="re-tradeAny__right">
                    <p className="title">
                      Trade Anywhere with the <br /> <span>
                        ryfin exchange
                      </span>
                    </p>
                    <div className="re-tradeAny__description-wrap">
                      <p className="description">
                        Lorem ipsum dolor sit amet consectetur. Cursus varius non ultrices blandit sapien erat ut. Quis a sit arcu sed. Nullam viverra.
                      </p>
                      <p className="description">
                        Lorem ipsum dolor sit amet consectetur. Cursus varius non ultrices blandit sapien erat ut. Quis a sit arcu sed. Nullam viverra.
                      </p>
                    </div>

                    <div className="re-theme__button-wrap d-flex justify-content-center justify-content-md-start">
                    <Link className="text-decoration-none" 
                    // to='/spot/ETH_USDT'
                    >                        <button className="re-theme__button--secondary">
                          Get Started
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="re-whyChoose position-relative">
            <img src={blurTwo} className="re-section__whyChoose-elipse--two position-absolute d-none d-xl-block" alt="elipse" />

            <div className="re-container">
              <p className="re-home__section--title-vh">
                Why Choose Ryfin Exchange
              </p>
              <div className="re-home__section--descWrap-vh mx-auto">
                <p className="re-home__section--desc-vh mt-3">
                  We are an leading digital currency exchange platform with a secure and peace of mind features for everyone who wish to invest and trade digital currency.
                </p>
              </div>
              {/* <div className="row">
                <div className="col-4 ">
                  <div className="re-whyChoose-card">

                  </div>
                </div>
                <div className="col-4 d-flex justify-content-center">
                  <div className="re-whyChoose-card">
                    <div className="re-whyChooose-card__image">
                      <img src={webSupport} alt="web support" className="" />
                    </div>
                  </div>
                </div>
                <div className="col-4 d-flex justify-content-end">

                  <div className="re-card">
                    <div className="re-box">
                      <div className="re-icon">
                        <div className="re-icon__border-one"></div>
                        <div className="re-icon__border-two"></div>

                        <div className="re-iconbox">
                          i
                        </div>
                      </div>
                      <div className="re-content">
                        <h3>design</h3>
                        <p>   We are an leading digital currency exchange platform with a secure and peace of mind features for everyone who wish to invest and trade digital currency.</p>
                        <a>Read more</a>
                      </div>
                    </div>

                  </div>
                </div>
              </div> */}
              <div className="re-whychoose__cards">
                <div className="re-whychoose__card" onMouseEnter={handleMouseHover} onMouseLeave={handleMouseRemove}>
                  <div className="re-whychoose__card--inner d-flex flex-column justify-content-between position-relative">
                    {/* <div className="re-whychoose__card--json position-absolute d-none">
                      <Lottie
                        animationData={choosejson}
                        className="re-whychoose__card--lottie "
                        loop={chooseLottieLoop} />
                    </div> */}
                    <div>
                      <p className="re-whychoose__card-title mb-0">
                        Manage Your Portfolio Easily
                      </p>
                      <p className="re-whychoose__card-desc mb-0">
                        Turn into a professional trader via our one-stop services platform with powerful features, high execution speed and low fees.
                      </p>
                    </div>

                    <div className="">
                    <Link className="text-decoration-none" 
                    // to='/spot/ETH_USDT'
                    >
                        <button className="re-whychoose__card-button">
                          Learnmore <FaAngleRight />
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="re-whychoose__card re-whychoose__card--two" onMouseEnter={handleMouseHover} onMouseLeave={handleMouseRemove}>
                  <div className="re-whychoose__card--inner d-flex flex-column justify-content-between position-relative">
                    {/* <div className="re-whychoose__card--json position-absolute d-none">
                      <Lottie
                        animationData={choosejson}
                        className="re-whychoose__card--lottie "
                        loop={chooseLottieLoop} />
                    </div> */}
                    <div>
                      <p className="re-whychoose__card-title mb-0">
                        Multi-Platform Web Support
                      </p>
                      <p className="re-whychoose__card-desc mb-0">
                        Buy and trade all your favorite tokens on RYFIN web effortlessly, anytime and anywhere.
                      </p>
                    </div>

                    <div className="">
                    <Link className="text-decoration-none" 
                    // to='/spot/ETH_USDT'
                    >                        <button className="re-whychoose__card-button">
                          Learnmore <FaAngleRight />
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="re-whychoose__card re-whychoose__card--three" onMouseEnter={handleMouseHover} onMouseLeave={handleMouseRemove}>
                  <div className="re-whychoose__card--inner d-flex flex-column justify-content-between position-relative">
                    {/* <div className="re-whychoose__card--json position-absolute d-none">
                      <Lottie
                        animationData={choosejson}
                        className="re-whychoose__card--lottie "
                        loop={chooseLottieLoop} />
                    </div> */}
                    <div>
                      <p className="re-whychoose__card-title mb-0">
                        Constant Support
                      </p>
                      <p className="re-whychoose__card-desc mb-0">
                        Premium 24/7/365 support is available to customers worldwide.
                      </p>
                    </div>

                    <div className="">
                    <Link className="text-decoration-none" 
                    // to='/spot/ETH_USDT'
                    >                        <button className="re-whychoose__card-button">
                          Learnmore <FaAngleRight />
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="re-join">
          <div className="container d-flex justify-content-center">
            <div className="">
              <p className="re-join__title text-center">
                Join the <span>ryfin exchange</span>  Community Today
              </p>
              <p className="re-home__section--desc text-center">
                Stay up-to-date with our latest news
              </p>
              <div className="d-flex justify-content-center">
              <Link className="text-decoration-none" 
                    // to='/spot/ETH_USDT'
                    >                  <button className="re-theme__button--secondary mt-4">
                    Start Trading Today
                  </button>
                </Link>
              </div>
              <div className="re-join__social ">
                <a href="www.youtube.com" target="_blank">
                  <button className="re-join__social-button">
                    {
                      currentTheme == 'light' ?
                        <>
                          <img src={youtubeLight} className="re-join__social-icon" />
                          <img src={youtubeLightHover} className="re-join__social-icon--hover" />
                        </> :
                        <>
                          <img src={youtube} className="re-join__social-icon" />
                          <img src={youtubeHover} className="re-join__social-icon--hover" />
                        </>
                    }

                  </button>
                </a>
                <a href="www.facebook.com" target="_blank">
                  <button className="re-join__social-button">
                    {
                      currentTheme == 'light' ?
                        <>
                          <img src={facebookLight} className="re-join__social-icon" />
                          <img src={facebookLightHover} className="re-join__social-icon--hover" />
                        </> :
                        <>
                          <img src={facebook} className="re-join__social-icon" />
                          <img src={facebookHover} className="re-join__social-icon--hover" />
                        </>
                    }

                  </button>
                </a>
                <a href="www.linkedin.com" target="_blank">
                  <button className="re-join__social-button">

                    {
                      currentTheme == 'light' ?
                        <>
                          <img src={linkedinLight} className="re-join__social-icon" />
                          <img src={linkedinLightHover} className="re-join__social-icon--hover" />
                        </> :
                        <>
                          <img src={linkedin} className="re-join__social-icon" />
                          <img src={linkedinHover} className="re-join__social-icon--hover" />
                        </>
                    }
                  </button>
                </a>
                <a href="www.discord.com" target="_blank">
                  <button className="re-join__social-button">

                    {
                      currentTheme == 'light' ?
                        <>
                          <img src={discordLight} className="re-join__social-icon" />
                          <img src={discordLightHover} className="re-join__social-icon--hover" />
                        </> :
                        <>
                          <img src={discord} className="re-join__social-icon" />
                          <img src={discordHover} className="re-join__social-icon--hover" />
                        </>
                    }
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="re-footer">
          <div className="re-container d-flex flex-wrap gap-4 align-items-center justify-content-between">
            <p className="re-footer__p mb-0">
              Copyright © 2024 StarDust All rights reserved.
            </p>
            <p className="re-footer__p mb-0">Terms of Services</p>
          </div>
        </section>

      </main >

      {/* <section className="whyUs pb-0">
        <div className="container">
          <h2 className="title1" data-aos="fade-up" data-aos-duration="1000">Why Choose AUREX</h2>
          <h6 className="mb-0" data-aos="fade-up" data-aos-duration="1000">We are an leading digital currency exchange platform with a secure and peace of mind features for everyone who wish to invest and trade digital currency.</h6>
          <div className="row row_why_choose pb-5">
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="card calc_card">
                <div className="card-body pt-3 pb-0">
                  <div className="feature_list feature_list1 text-center">
                    <div className="feature_text mt-4">
                      <img src={require("../assets/images/card_icon1.png")} alt="Secure Storage" className="img-fluid" />
                      <h5>Secure Storage</h5>
                      <p className="heading-text px-lg-0">
                        We support the crypto assets we are handling. We provide 24/7 real-time security monitoring for enhanced security.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div
              className="col-md-4  mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="card calc_card">
                <div className="card-body pt-3 pb-0">
                  <div className="feature_list feature_list1 text-center">
                    <div className="feature_text mt-4">
                      <img src={require("../assets/images/card_icon2.png")} alt="Optimized User Experience" className="img-fluid" />
                      <h5>Optimized User Experience</h5>
                      <p className="heading-text px-lg-0">
                        We have created a streamlined buying and selling experience with low rates. We understand our user's needs and wants.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="card calc_card">
                <div className="card-body pt-3 pb-0">
                  <div className="feature_list feature_list1 text-center">
                    <div className="feature_text mt-4">
                      <img src={require("../assets/images/card_icon3.png")} alt="Technical Stability" className="img-fluid" />
                      <h5>Technical Stability</h5>
                      <p className="heading-text px-lg-0">
                        Industry-leading technology which is upgraded frequently ensures a stable trading environment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="whyUs pt-0 pb-3">
        <div className="container">
          <h2 className="title1 mt-0" data-aos="fade-up" data-aos-duration="1000">Discover Our Products</h2>
          <h6 className="mb-0" data-aos="fade-up" data-aos-duration="1000">Trade a variety of products with AUREX based on your preferences.</h6>
          <div className="row row_why_choose pb-5">
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="card calc_card calc_card_new">
                <div className="card-body py-0">
                  <div className="feature_list feature_list1 text-center">
                    <div className="feature_text mt-4">
                      <img src={require("../assets/images/icon_spot.png")} alt="Spot" className="img-fluid" />
                      <h5 className="mt-0">Spot</h5>
                      <p className="heading-text px-lg-0">
                        The Aurex exchange is fast, safe and secure, allowing you to conduct transactions of digital currencies at any time with peace of mind.
                      </p>
                      <div className='btn_more_div text-center'>
                        <Link to="/spot">Learn More <i className='fas fa-arrow-right'></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div
              className="col-md-4  mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="card calc_card calc_card_new">
                <div className="card-body py-0">
                  <div className="feature_list feature_list1 text-center">
                    <div className="feature_text mt-4">
                      <img src={require("../assets/images/icon_future.png")} alt="Buy Crypto" className="img-fluid" />
                      <h5 className="mt-0">Buy Crypto</h5>
                      <p className="heading-text px-lg-0">
                        Buy and sell a wide range of cryptocurrencies quickly & easily.
                      </p>
                      <div className='btn_more_div text-center'>
                        <Link to="/spot">Learn More <i className='fas fa-arrow-right'></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="card calc_card calc_card_new">
                <div className="card-body py-0">
                  <div className="feature_list feature_list1 text-center">
                    <div className="feature_text mt-4">
                      <img src={require("../assets/images/icon_leveraged.png")} alt="Earn" className="img-fluid" />
                      <h5 className="mt-0">Earn</h5>
                      <p className="heading-text px-lg-0">
                        Invest and get some of the best rates in the industry - BTC, ETH, USDT and more. Stay turned for updates as we prepare new issues from here.</p>
                      <div className='btn_more_div text-center'>
                        <Link to="/spot">Learn More <i className='fas fa-arrow-right'></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section> */}

      {/* <div className="create_sec_home whyUs pt-0">
        <div className="container">
          <h2 className="title1 mt-0" data-aos="fade-up" data-aos-duration="1000">Trade Anywhere with the AUREX</h2>
          <h6 className="mb-0" data-aos="fade-up" data-aos-duration="1000">Standard web application</h6>
          <div className="row row_order_rev mt-md-5 pt-4 pb-5">
            <div className="col-12 col-md-6 mt-4 mt-md-0 sec_left_centr">
              <div className="sec_flex mb-4" data-aos="fade-up">
                <div className="btn_green_icon">
                  <img src={require("../assets/images/img_shield.png")} alt="Security" className="img-fluid" />
                </div>
                <div className="btn_desc_sec">
                  <p className="sec_title_crate">Manage Your Portfolio Easily</p>
                  <p className="sec_desc_create">Turn into a professional trader via our one-stop services platform with powerful features, high execution speed and low fees.</p>
                </div>
              </div>
              <div className="sec_flex mb-4" data-aos="fade-up">
                <div className="btn_green_icon">
                  <img src={require("../assets/images/img_shield.png")} alt="Security" className="img-fluid" />
                </div>
                <div className="btn_desc_sec">
                  <p className="sec_title_crate">Multi-Platform Web Support</p>
                  <p className="sec_desc_create">Buy and trade all your favorite tokens on AUREX web effortlessly, anytime and anywhere.</p>
                </div>
              </div>
              <div className="sec_flex mb-0" data-aos="fade-up">
                <div className="btn_green_icon">
                  <img src={require("../assets/images/img_shield.png")} alt="Security" className="img-fluid" />
                </div>
                <div className="btn_desc_sec">
                  <p className="sec_title_crate">Constant Support</p>
                  <p className="sec_desc_create">Premium 24/7/365 support is available to customers worldwide.</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex align-items-center">
              <img src={require("../assets/images/create_img.png")} alt="Security" className="img-fluid" />
            </div>

          </div>
        </div>
      </div> */}
      {/* <div className="create_sec_community whyUs pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-9 col-xl-8">
              <h2 className="title1 text-left" data-aos="fade-up" data-aos-duration="1000">Join the AUREX Community Today</h2>
              <h6 className="mb-0 text-left ml-0" data-aos="fade-up" data-aos-duration="1000">Always there for you</h6>
              <div className="social_btn_sec mt-4">
                <a href={socialMedia && socialMedia.twitterUrl} target="_blank" className="btn_outline_big_green mr-4">
                  <i className="fab fa-twitter fa-fw mr-3" aria-hidden="true"></i>
                  <span>Twitter</span>
                </a>
                <a href={socialMedia && socialMedia.telegramlink} target="_blank" className="btn_outline_big_green">
                  <i className="fab fa-telegram-plane fa-fw mr-3" aria-hidden="true"></i>
                  <span>Telegram</span>
                </a>
              </div>
              <div className="icon_social_sec mt-4">
                <div className="media_circle_round" target="_blank">
                  <a href={socialMedia && socialMedia.youtubelink} target="_blank" className="youtube_round">
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a href={socialMedia && socialMedia.facebookLink} target="_blank" className="facebook_round">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href={socialMedia && socialMedia.linkedinLink} target="_blank" className="linkedin_round">
                    <i class="fab fa-linkedin-in"></i>
                  </a>
                  <a href={socialMedia && socialMedia.discordlink} target="_blank" className="discord_round">
                    <i class="fab fa-discord"></i>
                  </a>
                  <a href={socialMedia && socialMedia.redditlink} target="_blank" className="reddit_round">
                    <i class="fab fa-reddit-alien"></i>
                  </a>
                  <a href={socialMedia && socialMedia.mediumlink} target="_blank" className="medium_round">
                    <i class="fab fa-medium-m"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div> */}
      {/* <Footer /> */}
    </div >

  );
}

export default HomePage;