import useThemeChange from "hooks/useThemeChange";
import React, { createContext, useState } from "react";

export const GlobalContext = createContext(null);

const GlobalState = ({ children }) => {

    const [currentTheme, setCurrentTheme] = useThemeChange("theme", "dark");
    const handleThemeChange = () => {
        setCurrentTheme(currentTheme === "light" ? "dark" : "light");
    };
    document.body.setAttribute("data-theme", currentTheme);

    return (

        <GlobalContext.Provider
            value={{
                currentTheme,
                handleThemeChange
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalState;
