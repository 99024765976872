/** npm import */

import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TimeAgo } from "@n1ru4l/react-time-ago";

// import 
import SocketContext from '../Context/SocketContext';

/** import react icons */

import { IoMdClose } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { FaArrowRight } from "react-icons/fa6";
import {
  IoNotifications,
  IoSunny,
  IoMoon,
  IoSettingsOutline,
} from "react-icons/io5";
import { MdHistory, MdOutlineDashboardCustomize } from "react-icons/md";
import { IoMdLogOut, IoMdChatbubbles } from "react-icons/io";
import { FaList, FaLock, FaRegUser } from "react-icons/fa";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { GrLink } from "react-icons/gr";
import { LuWallet } from "react-icons/lu";
import { CiLock } from "react-icons/ci";
import { FaUserFriends } from "react-icons/fa";


/** import local files */

import ryfinLogo from "../../assets/ryfin/images/logo.png";
import defaultProfileDark from "../../assets/ryfin/images/defualtProfileDark.png";
import defaultProfileLight from "../../assets/ryfin/images/defaultProfileLight.png";
import belldark from "../../assets/ryfin/images/belldark.svg";
import belllight from "../../assets/ryfin/images/belllight.svg";
import { userLogout, updateUserSettings } from "../../actions/users";

// import action
import {
  readNotification,
  FetchunReadNotice,
  noticePopup,
} from "../../actions/notificationAction";

//lib
import isEmpty from "lib/isEmpty";
import { GlobalContext } from "../../context/Context";
import { removeAuthToken } from "lib/localStorage";
import { removeAuthorization } from "config/axios";

const HeaderRight = (props) => {
  const socketContext = useContext(SocketContext)
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentTheme, handleThemeChange } = useContext(GlobalContext);

  const accountData = useSelector((state) => state.account);
  const { isAuth } = useSelector((state) => state.auth);
  const { unread, isOpen } = useSelector((state) => state.notice);
  console.log("unreadunread", unread)
  const [p2pRoomData, setp2pRoomData] = useState({})
  console.log("unreadunread", p2pRoomData)
  const {
    firstName,
    lastName,
    email,
    blockNo,
    address,
    state,
    city,
    postalCode,
    country,
  } = accountData;
  console.log("cosssssss", email);

  const readAllMsg = async () => {
    let { staus, message } = await readNotification();
    noticePopup(dispatch, false);
  };

  const logout = async (dispatch) => {
    history.push("/login");
    removeAuthToken();
    removeAuthorization();
    userLogout(dispatch);
  };

  useEffect(() => {
    // socket
    socketContext.socket.on('REQUEST', data => {
      console.log("DataDataData", data)
      setp2pRoomData(data)
    })
  }, [])

  const ClearMessage = () => {

  }

  console.log("p2pRoom", p2pRoomData);

  return (
    <div className="re-header__right ">
      <button
        onClick={handleThemeChange}
        className="border-0 outline-0 bg-transparent px-3"
      >
        {currentTheme == "light" ? (
          <IoSunny fontSize={20} fill="#CCA449" />
        ) : (
          <IoMoon fontSize={20} fill="#CCA449" />
        )}
      </button>
      {isAuth ? (
        <>
          <div className="hover_tooltip hover_tooltip_right h-100 d-flex align-items-center px-3">
            <button className="bg-transparent border-0 outline-0 position-relative p-0">
              <IoNotifications fontSize={20} fill="#CCA449" />

              {unread && unread.length > 0 ? (
                <div className="re-notification__count">
                  {unread && unread.length}
                </div>
              ) : (
                <></>
              )}
            </button>
            <div className="hover_tooltip_content ">
              {!isEmpty(unread) && unread.length > 0 ? (
                <>
                  <div className="re-header__notificaion--top">
                    <p className="re-header__dropdown-title mb-0">
                      Notifications
                    </p>
                    <button
                      className="re-header__notification-button--markall"
                      onClick={() => {
                        readAllMsg();
                      }}
                    >
                      Mark all as read
                    </button>
                  </div>

                  <ul className="hover_tooltip_ul mt-2 re-header__notificaion-body--ul">
                    {unread &&
                      unread.length > 0 &&
                      unread.map((item) => {
                        return (
                          <li className="mb-0">
                            <div className="re-header__notification">
                              <div className="row mx-auto align-items-center">
                                <div className="col-2 px-0">
                                  <div className="re-header__notification--imageWrap">
                                    <img
                                      src={
                                        currentTheme == "light"
                                          ? belldark
                                          : belllight
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-7 px-2">
                                  <p className="re-header__notification--content mb-0">
                                    {item.description}
                                  </p>
                                </div>
                                <div className="col-3 px-0 d-flex justify-content-end">
                                  <p className="re-header__notification--time mb-0 text-end">
                                    {" "}
                                    <TimeAgo date={new Date(item.createdAt)}>
                                      {({ value }) => value}
                                    </TimeAgo>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                  <div className="d-flex align-items-center justify-content-center re-header__notification-view">
                    <Link to="/notification">
                      <button className="border-none bg-transparent outline">
                        View all
                      </button>
                    </Link>
                  </div>
                </>
              ) : (
                <div className="re-header__notification-none">
                  <p className="re-header__dropdown-title mb-0">
                    Notifications
                  </p>
                  <p className="mb-0 text-center">
                    No more unread Notifications ..
                  </p>
                  <div className="d-flex align-items-center justify-content-center re-header__notification-view">
                    <Link to="/notification">
                      <button className="border-none bg-transparent outline">
                        View All
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="hover_tooltip hover_tooltip_right h-100 d-flex align-items-center px-3">
            <button className="bg-transparent border-0 outline-0 position-relative p-0">
              <IoMdChatbubbles fontSize={20} fill="#CCA449" />

              {unread && unread.length > 0 ? (
                <div className="re-notification__count">
                  {unread && unread.length}
                </div>
              ) : (
                <></>
              )}
            </button>
            <div className="hover_tooltip_content ">
              {!isEmpty(unread) && unread.length > 0 ? (
                <>
                  <div className="re-header__notificaion--top">
                    <p className="re-header__dropdown-title mb-0">
                      Message
                    </p>
                    <button
                      className="re-header__notification-button--markall"
                      onClick={() => {
                        readAllMsg();
                      }}
                    >
                      Mark all as read
                    </button>
                  </div>

                  <ul className="hover_tooltip_ul re-header__notificaion-body--ul">
                    {unread &&
                      unread.length > 0 &&
                      unread.map((item) => {
                        return (
                          <li className="mb-0">
                            <div className="re-header__notification">
                              <NavLink to={`/trade/${p2pRoomData?.roomid}`}>
                                <p onClick={(e) => ClearMessage} className="re-header__notification--content mb-0">You have one message from testtesttesttesttest!...</p>
                              </NavLink>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                  <div className="d-flex align-items-center justify-content-center re-header__notification-view">
                    <Link to="/all-message">
                      <button className="border-none bg-transparent outline">
                        View all
                      </button>
                    </Link>
                  </div>
                </>
              ) : (
                <div className="re-header__notification-none">
                  <p className="re-header__dropdown-title mb-0">
                    Message
                  </p>
                  <p className="mb-0 text-center">
                    No more unread message ...
                  </p>
                  <div className="d-flex align-items-center justify-content-center re-header__notification-view">
                    <Link to="/all-message">
                      <button className="border-none bg-transparent outline">
                        View All
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="hover_tooltip hover_tooltip_right h-100 d-flex align-items-center px-3">
            <CgProfile fontSize={24} color="#CCA449" />
            <div className="hover_tooltip_content">
              <div className="re-header__dropdown-profile">
                <div className="re-header__dropdown-profile--header">
                  <div className="re-header__dropdown-profileWrap">
                    <img
                      src={
                        currentTheme == "light"
                          ? defaultProfileDark
                          : defaultProfileLight
                      }
                    />
                  </div>
                  <p className="re-header__dropdown-profile--email mb-0">
                    {email ? email : "Fetching email"}
                  </p>
                </div>
                <div className="re-header__dropdown-profile--body">
                  <ul>
                    <li>
                      <NavLink
                        to="/dashboard"
                        className="text-decoration-none mb-2"
                      >
                        <div className="re-header__dropdown--list mb-0">
                          {/* <FaArrowRight className="nav_hover-icon--right" /> */}
                          <MdOutlineDashboardCustomize
                            fontSize={20}
                            className="nav_hover-icon--right"
                          />

                          <p className="re-header__dropdown-desc">Dashboard</p>
                        </div>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/wallet"
                        className="text-decoration-none mb-2"
                      >
                        <div className="re-header__dropdown--list mb-0">
                          <LuWallet
                            fontSize={20}
                            className="nav_hover-icon--right"
                          />
                          <p className="re-header__dropdown-desc">Wallet</p>
                        </div>
                      </NavLink>
                    </li>



                    <li>
                      <NavLink
                        to="/profile"
                        className="text-decoration-none mb-2"
                      >
                        <div className="re-header__dropdown--list mb-0">
                          <FaRegUser
                            fontSize={17}
                            className="nav_hover-icon--right"
                          />

                          <p className="re-header__dropdown-desc">Profile</p>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/security"
                        className="text-decoration-none mb-2"
                      >
                        <div className="re-header__dropdown--list mb-0">
                          <CiLock
                            fontSize={22}
                            className="nav_hover-icon--right"
                          />

                          <p className="re-header__dropdown-desc">Security</p>
                        </div>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/orders"
                        className="text-decoration-none mb-2"
                      >
                        <div className="re-header__dropdown--list mb-0">
                          <FaList
                            fontSize={16}
                            className="nav_hover-icon--right"
                          />

                          <p className="re-header__dropdown-desc">Orders</p>
                        </div>
                      </NavLink>
                    </li>



                    <li>
                      <NavLink
                        to="/history"
                        className="text-decoration-none mb-2"
                      >
                        <div className="re-header__dropdown--list mb-0">
                          <MdHistory
                            fontSize={21}
                            className="nav_hover-icon--right"
                          />

                          <p className="re-header__dropdown-desc">History</p>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/referral"
                        className="text-decoration-none mb-2"
                      >
                        <div className="re-header__dropdown--list mb-0">
                          <FaUserFriends
                            fontSize={17}
                            className="nav_hover-icon--right"
                          />

                          <p className="re-header__dropdown-desc">Referral</p>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/support-ticket"
                        className="text-decoration-none mb-2"
                      >
                        <div className="re-header__dropdown--list mb-0">
                          <AiOutlineQuestionCircle
                            fontSize={19}
                            className="nav_hover-icon--right"
                          />

                          <p className="re-header__dropdown-desc">Support</p>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/setting"
                        className="text-decoration-none mb-2"
                      >
                        <div className="re-header__dropdown--list mb-0">
                          <IoSettingsOutline
                            fontSize={19}
                            className="nav_hover-icon--right"
                          />
                          <p className="re-header__dropdown-desc">Settings</p>
                        </div>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/api-management"
                        className="text-decoration-none mb-2"
                      >
                        <div className="re-header__dropdown--list mb-0">
                          <GrLink
                            fontSize={19}
                            className="nav_hover-icon--right"
                          />
                          <p className="re-header__dropdown-desc">
                            API Management
                          </p>
                        </div>
                      </NavLink>
                    </li>


                    <li>
                      <button
                        className="border-0 outline-0 bg-transparent px-0 re-header__dropdown--list mb-0"
                        onClick={() => logout(dispatch)}
                      >
                        <IoMdLogOut
                          fontSize={20}
                          fill={currentTheme == "light" ? "#000" : "#fff"}
                          className="nav_hover-icon--right"
                        />
                        <p className="re-header__dropdown-desc">Logout</p>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <button
            className="re-header__right--login"
            onClick={() => history.push("/login")}
          >
            <p>login</p>
          </button>
          <button
            className="re-header__right--signup"
            onClick={() => history.push("/register")}
          >
            Signup
          </button>
        </>
      )}
    </div>
  );
};

export default HeaderRight;
