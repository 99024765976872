import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { IoClose } from "react-icons/io5";


const Canceltrademodal = (props) => {
    const [isoffertagmodal, setIsoffertagmodal] = useState(false);

    const [taglist, setTaglist] = useState([]);
    const [offertag, setOffertag] = useState([]);
    // useEffect(()=>{
    //     setTaglist(props?.taglist);
    //     setOffertag(props?.ofrtag)
    // },[])

    const handleclick = (data) => {
        var offerdata = offertag;
        if (offerdata?.length < 3) {
            var index = offerdata.indexOf(data);
            if (index > -1) {
                offerdata.splice(index, 1);
            } else {
                offerdata.push(data);
            }
            setOffertag(offerdata);
            setTaglist(props?.taglist);
            props.onSet(offerdata);
        }
    }
    return (
        <>
            <Modal show={isoffertagmodal} size="md" centered >  {/*show={created}*/}
                {/* <img className='spring1' src={spring} alt="spring" /> */}
                {/* <img src={Images.connect} className='connectright1' /> */}
                <div className='modalz'>
                    {/* <img src={Images.connect} className='vv1' /> */}
                    {/* <span className='greengradient'></span> */}
                </div>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <p className='mb-0'>Cancel Trade</p>
                        {/* <p className='submod-title'>It is a long established fact that a reader</p> */}
                    </Modal.Title>
                    {/* <Button variant="secondary" className='modalbtns' onClick={props?.onDismiss}> x
                    </Button> */}
                    <button className='modalbtns' onClick={props?.onDismiss}>
                        <IoClose />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='py-4'>

                        <p className=' roboto'>Sure, Do you want to cancel this trade?</p>
                        {/* <div className='scroll_div_table'>
                                        {taglist?.map((data , i) =>  <div className={offertag.includes(data) ? 'grid_div py-3 bordered_div_each active' : 'grid_div py-3 bordered_div_each'} onClick={()=>handleclick(data)}>
                                        <p className='mb-0 content_grid'>{data.Name}</p>{console.log("Mappingdata" , offertag.includes(data))}
                                        <p className='mb-0 content_grid'>{data.Description}</p>
                                        </div>)}
                                        </div> */}

                    </div>
                    <div className='d-flex justify-content-end'>
                        <button className='re-inner_modal-btn--cancel' onClick={() => props.onDismiss()}>Cancel</button>
                        <button className='re-inner_modal-btn ml-3' onClick={() => props.oncancel()}>Ok</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Canceltrademodal;