import React from 'react'
import { NavLink } from 'react-router-dom'

const HeaderCenter = () => {

    const location = window.location.pathname

    return (
        <ul>
            <li>
                <NavLink className={`${location.includes('/spot/') ? "active" : ""}`} to='/spot/ETH_USDT'>Spot</NavLink>
            </li>
            <li>
                <NavLink to='/launchpad'>Launchpad</NavLink>
            </li>
            <li>
                <NavLink to='/staking'>Stake</NavLink>
            </li>
            <li>
                <NavLink to='/viewoffers/Buy/BTC'>P2P</NavLink>
            </li>
        </ul>
    )
}

export default HeaderCenter