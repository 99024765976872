
// npm import
import React, { useEffect, useState } from 'react'
import { Link, useHistory, } from 'react-router-dom'
import { RxHamburgerMenu } from "react-icons/rx";


// local file import

import useThemeChange from '../../hooks/useThemeChange';
import ryfinLogo from '../../assets/ryfin/images/logo.png'
import { IoMdClose } from "react-icons/io";


// import local files

import HeaderRight from './HeaderRight';
import HeaderCenter from './HeaderCenter';


const HeaderNew = () => {

    const location = window.location.pathname
    console.log('locationnn', location)
    const [floatingHeader, setFloatingHeader] = useState(false);

    function openNav() {
        document.getElementById("mySidenav").style.width = "300px";
    }

    function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
    }

    useEffect(() => {
        const handleHeaderChange = () => {
            if (window.scrollY > 5) {
                setFloatingHeader(true);
            } else {
                setFloatingHeader(false);
            }
        };
        window.addEventListener("scroll", handleHeaderChange);
        return () => {
            window.removeEventListener("scroll", handleHeaderChange);
        };
    }, [floatingHeader]);

    return (
        <>
            <header className={`re-header ${floatingHeader ? "active" : ""}`}>
                <div className={`${location.includes('/spot') ? 'px-2 h-100' : 're-container'}`}>
                    <div className='d-flex align-items-center justify-content-between h-100'>
                        <Link to='/'>
                            <img src={ryfinLogo} className='re-logo' />
                        </Link>
                        <button className='d-lg-none re-header__hamburger' onClick={openNav}>
                            <RxHamburgerMenu />
                        </button>
                        <nav className='header-re__navs d-none d-lg-flex'>
                            <HeaderCenter />
                        </nav>
                        <div className='d-none d-lg-flex h-100'>
                            <HeaderRight />
                        </div >
                    </div >
                </div >
            </header >

            {/* start of offcanva */}

            <div div id="mySidenav" class="custom_sidenav" >
                <div className='re-sidebar__wrapper'>

                    <div className='d-flex align-items-center justify-content-between'>
                        <Link>
                            <img src={ryfinLogo} className='re-logo' />
                        </Link>
                        <button onClick={closeNav} className='d-lg-none re-header__hamburger'>
                            <IoMdClose />
                        </button>
                    </div>
                    <nav className='header-re__navs--mob'>
                        <HeaderCenter />
                    </nav>

                    <div className='h-100 d-lg-none'>
                        <HeaderRight />
                    </div>
                </div>

            </div >

            {/* end of offcanva */}
        </>
    )
}

export default HeaderNew