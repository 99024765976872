const Images = {
    // bannerbg: require("./assets/P2Passets/images/toss/bannerbg.png"),
    bannerbg: require("./assets/P2Passets/images/toss/bannerbg.png"),
    connect: require("./assets/P2Passets/images/toss/connect.png"),
    eth: require("./assets/P2Passets/images/toss/eth.png"),
    bitcoin: require("./assets/P2Passets/images/toss/bitcoin.png"),
    secure: require("./assets/P2Passets/images/toss/secure.png"),
    hack: require("./assets/P2Passets/images/toss/hack.png"),
    key: require("./assets/P2Passets/images/toss/key.png"),
    tab1: require("./assets/P2Passets/images/toss/tab1.png"),
    tab2: require("./assets/P2Passets/images/toss/tab2.png"),
    tab3: require("./assets/P2Passets/images/toss/tab3.png"),
    tab4: require("./assets/P2Passets/images/toss/tab4.png"),
    tab5: require("./assets/P2Passets/images/toss/tab5.png"),
    tab6: require("./assets/P2Passets/images/toss/tab6.png"),
    prof: require("./assets/P2Passets/images/toss/prof.png"),
    fee: require("./assets/P2Passets/images/toss/fee.png"),
    shield: require("./assets/P2Passets/images/toss/shield.png"),
    graduate: require("./assets/P2Passets/images/toss/graduate.png"),
    excrow: require("./assets/P2Passets/images/toss/excrow.png"),
    connect1: require("./assets/P2Passets/images/toss/Tossvtoss/Mask Group 2.png"),
    connectx: require("./assets/P2Passets/images/toss/Tossvtoss/xmen.png"),
    india: require("./assets/P2Passets/images/toss/india.png"),

    crypt1: require("./assets/P2Passets/images/toss/crypt1.png"),
    crypt2: require("./assets/P2Passets/images/toss/crypt2.png"),
    crypt3: require("./assets/P2Passets/images/toss/crypt3.png"),
    crypt4: require("./assets/P2Passets/images/toss/crypt4.png"),


    ticket: require("./assets/P2Passets/images/toss/ticket.png"),
    orangecoin: require("./assets/P2Passets/images/toss/orangecoin.png"),
    tag: require("./assets/P2Passets/images/toss/tag.png"),
    fixedprice: require("./assets/P2Passets/images/toss/fixedprice.png"),
    fb: require("./assets/P2Passets/images/toss/fb.png"),
    email: require("./assets/P2Passets/images/toss/email.png"),
    twitter: require("./assets/P2Passets/images/toss/twitter.png"),
    cog: require("./assets/P2Passets/images/toss/cog.png"),
    user: require("./assets/P2Passets/images/toss/user.png"),
    homefaq1: require("./assets/P2Passets/images/toss/secure_con_1.png"),
    homefaq2: require("./assets/P2Passets/images/toss/invite_cont_2.png"),
    homefaq3: require("./assets/P2Passets/images/toss/feed_cont_3.png"),
    btclogo: require("./assets/P2Passets/images/toss/btc.png"),
    usdtlogo: require("./assets/P2Passets/images/toss/usdt.png"),
    xrplogo: require("./assets/P2Passets/images/toss/xrp.png"),
    ethlogo: require("./assets/P2Passets/images/toss/ethlogo.png"),
    profill: require("./assets/images/prifileimg.png")
}

export default Images