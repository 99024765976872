// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";

// export default function useThemeChange(themes, defaultvalue) {
//     const { theme } = useSelector((state) => state.theme);
//     console.log("getUsergetUser", theme);
//     const [value, setValue] = useState('dark');
//     useEffect(() => {
//         setValue(() => {
//             let currentValue;

//             try {
//                 currentValue = JSON.parse(
//                     localStorage.getItem(key)
//                     || String(defaultvalue)
//                 );
//             } catch (error) {
//                 console.log(error);
//                 currentValue = defaultvalue;
//             }

//             return currentValue;
//         })
//     }, [theme]);
//     return [value, setValue];
// }

import { useEffect } from "react";
import { useState } from "react";

export default function useThemeChange(key, defaultValue) {
    const [value, setValue] = useState(() => {
        let currentValue;
        try {
            currentValue = JSON.parse(
                localStorage.getItem(key)
                || String(defaultValue)
            );
        } catch (error) {
            console.log(error);
            currentValue = defaultValue;
        }

        return currentValue;
    });
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
}